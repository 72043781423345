// menu
const dlClose = document.querySelectorAll('.dl-close');
const menuwrapper = document.querySelector('.dl-menu');
const dlMenuwrapperBtn = document.querySelector('.dl-menuwrapper__btn');


dlClose.forEach((item) => {
  item.addEventListener('click', () => {
    menuwrapper.classList.remove('dl-menuopen');
    dlMenuwrapperBtn.classList.remove('dl-active');
    menuwrapper.classList.add('dl-menu-toggle');
  })
})

// ввод только цифры
document.querySelectorAll('.form__item-input--tel').forEach(elem => {
  elem.addEventListener('input', function (e) {
    let target = e.target;
    target.value = target.value.replace(/[^+\d]/g, '');
  });
})

// скролл до определенного места и убрать
const menuFixed = document.querySelector('.menu__fixed');

window.addEventListener('scroll', function () {
  if(window.scrollY > 185) {
    menuFixed.classList.add('active');
  } else {
    menuFixed.classList.remove('active')
  }
})

// скролл до определенного места и убрать
const menuMedium = document.querySelector('.menu__fixed-medium');

window.addEventListener('scroll', function () {
  if(window.scrollY > 144) {
    menuMedium.classList.add('active');
  } else {
    menuMedium.classList.remove('active')
  }
})

// const dlMenu = document.querySelectorAll('.dl-menu');

// dlMenu.forEach(item => {
//   item.addEventListener('click', (event) => {
//     let target = event.target;
//     console.log(target);
//   })
// })

