import { validateForms } from '../functions/validate-forms';

const rules = [
  {
    ruleSelector: '.form__item-input--tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  }
];

const afterForm = () => {
  console.log('Произошла отправка!');
};

validateForms('.form__inner', rules, afterForm);


